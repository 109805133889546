<template>
    <div>
        <breadcrumbs
            :title="'Skupovi'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Skupovi</h5>
                <br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi skup
                </button>
            </div>

        </div>
        <div class="ibox-content">
            <datatables
                v-if="show_datatable"
                :url="datatables_url"
                :settings="datatables.settings"
                :title="datatables.title"
                @rowClick="rowClick"
                 @buttonClick="buttonClick"
            ></datatables>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../components/datatable/Datatables";
import axios from "axios";
import toastr from "toastr";
export default {
    name: "Skupovi",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'skup',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/create' } )
        },

         buttonClick( col, row ){
            let vm = this
            vm.show_datatable = false
            axios.get( process.env.VUE_APP_API_URL + 'regis-skup/' + row.id + '/pending' )
                .then( response => {
                    vm.show_datatable = true
                    toastr.success( 'Skup je dodan na listu za slanje!' )
                } )
                .catch( e => {
                    vm.show_datatable = true
                    console.log( e )
                } )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id': 'id',
                            'visible': false
                        },
                        {
                            'id': 'broj_skupa',
                            'title': 'Broj skupa',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'naziv',
                            'title': 'Naziv',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'vrsta_skupa_id',
                            'title': 'Vrsta skupa',
                            'type' : function( row ){
                                return row.vrsta_skupa ? row.vrsta_skupa.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'vrsta-skupa', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme_od',
                            'title': 'Datum početka',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'datum_i_vrijeme_do',
                            'title': 'Datum završetka',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'podrucje_teme_id',
                            'title': 'Područje teme',
                            'type' : function( row ){
                                return row.podrucje_teme ? row.podrucje_teme.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'podrucje-teme', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },
                       /*  {
                            'id': 'send',
                            'title': 'Regis',
                            'searchable': false,
                            'type': 'button',
                            'filter': {
                                'type': 'none',
                                'search': '',
                                'value': null,
                            },
                            'column_visibility_group': {
                                id: 'skup',
                            },
                            config: {
                                title: 'Pošalji',
                                type: 'primary',
                            }
                        }, */
                        {
                            'id': 'regis_status',
                            'title': 'Regis status',
                            'type' : function( row ){
                                if( row.regis_status === 'pending' )
                                    return 'Čeka na slanje'
                                if( row.regis_status === 'success' )
                                    return 'Poslano'
                                if( row.regis_status === 'faild' )
                                    return 'Greška pri slanju'
                            },
                            'filter': {
                                'type': 'nene',
                                'search': '',
                            },
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'regis_log',
                            'title': 'Regis log',
                            'type' : function( row ){
                                return row.regis_log
                            },
                            'filter': {
                                'type': 'nene',
                                'search': '',
                            },
                            'column_visibility_group': {
                                'id': 'skup',
                                'visible' : true
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'skup' : 'Skupovi'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Skupovi'
            }

            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi',
                    name: 'Skupovi'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
